@keyframes fadeRight{
    0%{
        opacity: 0;
        transform: translateX(-100px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}

.navbar-container{
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-around;

}

button{
    background: inherit;
    border: none;
    color: #fff;
    width: auto;
    height: 50px;
    text-align: center;
    font-size: 30px;
    letter-spacing: 5px;
    display: flex;
    flex-direction: column-reverse;
    font-family: inherit;
    position: relative;
    animation: fadeRight 2s;
    outline: none;
}

button::after{
    background-color: #fff;
    content: "";
    width: 0px;
    height: 1px;
    transition: width 0.35s ease 0s;
    position: absolute;
    text-align: center;
}

button:focus::after{
    width: 100%;
}

@media only screen and (max-width: 600px) {
       
}