.work-main-container{
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
}

h4{
    font-size: 24px;
    margin: 0;
    text-decoration: underline;
}

h5{
    font-size: 16px;
    margin: 0;
    margin-bottom: 10px;
}

.work-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    padding: 0 32px;
}

.work-info-container > .work-info-one { 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.work-info-one > p {
    text-align: center;
    padding: 0px 32px;
}

.education-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    padding: 0 32px;
}

.education-info-container > .education-info-one,
.education-info-container > .education-info-two,
.education-info-container > .education-info-three { 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.education-info-one > p,
.education-info-two > p,
.education-info-three > p { 
    text-align: center;
    padding: 0 32px;
}

@media only screen and (max-width: 992px) { 
    h4{
        font-size: 18px;
        margin: 0;
    }
    
    h5{
        font-size: 14px;
        margin: 0;
        margin-bottom: 10px;
    }

    p{
        font-size: 12px;
    }
}

