.about-main-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.about-main-container > img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: 1px solid #fff;
}

.about-main-container > p {
    color: #fff;
    font-size: 1rem;
    text-align: center;
    padding: 0px 64px;
}

@media only screen and (max-width: 600px) {
       
}