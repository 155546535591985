.projects-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 64px;
}

.project-info-one {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 16px;
  color: #fff;
  padding: 0px 32px;
}

.project-info-one > a {
  width: fit-content;
  font-size: 24px;
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}

.project-info-one > a::after {
  background-color: white;
  content: "";
  width: 0px;
  height: 1px;
  transition: width 0.35s ease 0s;
  position: absolute;
  text-align: center;
}

.project-info-one > a:hover::after {
  width: 100%;
}

.project-info-one > p {
  font-size: 18px;
  width: 100%;
}

.project-info-two {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 16px;
  color: #fff;
  padding: 0px 32px;
}

.project-info-two > a {
  width: fit-content;
  font-size: 24px;
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}

.project-info-two > a::after {
  background-color: white;
  content: "";
  width: 0px;
  height: 1px;
  transition: width 0.35s ease 0s;
  position: absolute;
  text-align: center;
}

.project-info-two > a:hover::after {
  width: 100%;
}

.project-info-two > p {
  font-size: 18px;
  width: 100%;
}

.project-info-three {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: #fff;
  gap: 16px;
  padding: 0px 32px;
}

.project-info-three > a {
  width: fit-content;
  font-size: 24px;
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}

.project-info-three > a::after {
  background-color: white;
  content: "";
  width: 0px;
  height: 1px;
  transition: width 0.35s ease 0s;
  position: absolute;
  text-align: center;
}

.project-info-three > a:hover::after {
  width: 100%;
}

.project-info-three > p {
  font-size: 18px;
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .project-info-one > a {
    font-size: 18px;
  }

  .project-info-one > p {
    font-size: 12px;
  }

  .project-info-two > a {
    font-size: 18px;
  }

  .project-info-two > p {
    font-size: 12px;
  }

  .project-info-three > a {
    font-size: 18px;
  }

  .project-info-three > p {
    font-size: 12px;
  }
}
