.contact-form{
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 80%;
    height: fit-content;
    border: 1px solid #fff;
    padding: 64px 48px;
    position: relative;
}

.name-input, .email-input{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.message-input{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

label{
    color: white;
    font-size: 18px;
}

div > input{
    background: inherit;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    outline: none;
    width: 60%;
    font-size: 16px;
    font-family: inherit;
}

textarea{
    background: inherit;
    border: none;
    resize: none;
    border: 1px solid white;
    height: 100px;
    width: 80%;
    color: white;
    outline: none;
    font-size: 16px;
    font-family: inherit;
    padding-left: 2%;
}

.icons-and-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.submit-button{
    font-size: 18px;
    border: 1px solid #fff;
    text-align: center;
    background-color: inherit;
    color: #fff;
    cursor: pointer;
    width: fit-content;
    padding: 12px 32px;
}
.submit-button:hover{
    color: black;
    background-color: #fff;
}

.icons-container {
    position: absolute;
    display: flex;
    gap: 32px;
    top: 32px;
    right: 32px
}

@media only screen and (max-width: 992px) { 
    
    label{
        font-size: 14px;
    }

    div > input { 
        font-size: 14px;
    }

    textarea {
        font-size: 14px;
    }

    .submit-button { 
        font-size: 14px;
    }
}
