.App {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(to right, #0404045b, #000000, #656565be),
    url(./Images/artur-shamsutdinov-7MJpHQdGXGA-unsplash.jpg);
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.app-container-two {
  width: 100%;
  height: 100%;
}

.app-four-quadrant-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.app-quadrant-about,
.app-quadrant-projects,
.app-quadrant-work,
.app-quadrant-contact { 
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    align-items: center;
    transition: .50s;
    cursor: pointer;
    box-sizing: border-box;
    padding-top: 32px;
}

.app-quadrant-about.--active,
.app-quadrant-projects.--active,
.app-quadrant-work.--active,
.app-quadrant-contact.--active { 
    width: 100%;
    height: 100%;
}

.app-quadrant-contact.--active { 
  padding: 0 64px 32px 64px;
}

.app-quadrant-about.--inactive,
.app-quadrant-projects.--inactive,
.app-quadrant-work.--inactive,
.app-quadrant-contact.--inactive {
    width: 0%;
    height: 0%;
}

.app-quadrant-about.--inactive > h1,
.app-quadrant-projects.--inactive > h1,
.app-quadrant-work.--inactive > h1,
.app-quadrant-contact.--inactive > h1 {
  display: none;
}

.app-quadrant-about.--active:hover,
.app-quadrant-projects.--active:hover,
.app-quadrant-work.--active:hover,
.app-quadrant-contact.--active:hover { 
    border: none;
    cursor: default;
}

.app-quadrant-about:hover,
.app-quadrant-projects:hover,
.app-quadrant-work:hover,
.app-quadrant-contact:hover {
    border: 1px solid white;
}

.app-quadrant-about > h1,
.app-quadrant-projects > h1,
.app-quadrant-work > h1,
.app-quadrant-contact > h1 { 
    color: rgb(255, 255, 255);
    
}

.back-to-main { 
    position: absolute;
    top: 12px;
    left: 12px;
    color: white;
    cursor: pointer;
}

@keyframes animated-text {
  from {
    width: 0;
  }
  to {
    width: 500px;
  }
}

@media only screen and (max-width: 992px) { 
  .app-quadrant-about > h1,
  .app-quadrant-projects > h1,
  .app-quadrant-work > h1,
  .app-quadrant-contact > h1 { 
    font-size: 18px;
  }
}
